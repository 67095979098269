import { mediaUri } from "../rest"


/**
 * Display the channel avatar
 * 
 * @param {*} param0 
 * @returns 
 */
export function Avatar({ channel, size }) {
  return <img
    src={avatarUri(channel)}
    alt={channel.name}
    style={{
      height: size,
      width: size,
      borderRadius: size / 2,
    }}
  />
}

/**
 * Display the organization banner
 * 
 * @param {*} param0 
 * @returns 
 */
export function Banner({ channel }) {
  return <img
    src={bannerUri(channel)}
    alt={channel.name}
    style={{
      width: "100%",
      objectFit: "cover",
    }}
  />
}

/**
 * Get the channel avatar uri. Fallback to the organization avatar
 * 
 * @param {*} channel 
 * @returns 
 */
export function avatarUri(channel) {
  return channel.avatar
    ? mediaUri(channel.avatar)
    : (channel.organization && channel.organization.avatar
      ? mediaUri(channel.organization.avatar)
      : null
    )
}

/**
 * Get the channel avatar uri. Fallback to the organization avatar
 * 
 * @param {*} channel 
 * @returns 
 */
export function bannerUri(channel) {
  const organization = channel.organization
  return organization && organization.banner
    ? mediaUri(organization.banner)
    : null
}
